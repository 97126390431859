<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Phosphorus pentachloride,
        <chemical-latex content="PCl5," />
        is a precursor for an electrolyte material used in lithium ion batteries. It decomposes as
        shown below:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="PCl5(g) <=> PCl3(g) + Cl2(g)" />
        <stemble-latex content="$\qquad\text{K}_\text{c}=1.80\text{ at }250^\circ\text{C}$" />
      </p>

      <p class="mb-3">
        If
        <number-value :value="molPCl5" unit="\text{mol}" />
        of
        <chemical-latex content="PCl5" />
        is placed in a
        <number-value :value="volume" unit="\text{L}" />
        reaction vessel at
        <stemble-latex content="$250^\circ\text{C,}$" />
        determine the concentrations of
        <chemical-latex content="PCl5" />
        and
        <chemical-latex content="PCl3" />
        when the reaction reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.PCl5"
        prepend-text="$\ce{[PCl5]}:$"
        append-text="$\text{M}$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.PCl3"
        prepend-text="$\ce{[PCl3]}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question118',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        PCl5: null,
        PCl3: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    molPCl5() {
      return this.taskState.getValueBySymbol('molPCl5').content;
    },
  },
};
</script>
